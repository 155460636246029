<template>
  <div class="fill-height">
    <section id="bi-cc-geral" class="fill-height"/>
  </div>
</template>

<script>
import * as pbi from 'powerbi-client'

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'PowerBiCarteiraClienteGeral',
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'BI', route: 'carteira_clientes_geral' },
      { title: 'Carteira Clientes - Geral' },
    ])

    const permissions = pbi.models.Permissions.All
    const accessToken = 'eyJrIjoiNGM1NzMzODYtMDU4OS00NGVjLTllODQtN2Y0ZTRiMzQ1YTc2IiwidCI6ImI5OTdiNWVkLTM1YzQtNDliMS1hYmZjLWRlNTQzZmQ2MmY3NiJ9'

    const config = {
      type: 'dashboard',
      tokenType: pbi.models.TokenType.Embed,
      accessToken: accessToken,
      embedUrl: `https://app.powerbi.com/view?r=${accessToken}`,
      id: 'CarteiraClientes',
      pageView: 'fitToWidth',
      permissions: permissions,
    }

    const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory)

    const dashboardContainer = document.getElementById('bi-cc-geral')
    const dashboard = powerbi.embed(dashboardContainer, config)

    dashboard.off('loaded')
    dashboard.off('rendered')

    dashboard.on('error', function () {
      this.dashboard.off('error')
    })
  },
}
</script>
